<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getTemplates"
              @onEdit="onEdit"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <templates-editor
      v-if="editorOpen"
      :template="selectedTemplate"
      @close="closeEditor"
      @saved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import TemplatesEditor from './TemplatesEditor.vue';
import '@/utils/ucwords';

export default {
  components: {
    VueTable,
    TemplatesEditor,
  },
  props: [],
  data() {
    return {
      editorOpen: false,
      selectedTemplate: null,
      vTable: {
        headers: [
          {
            title: 'title',
            sortable: true,
          },
          {
            title: 'category',
            sortable: true,
            callback: (i) => i.ucwords(),
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit Template',
            callback: 'onEdit',
            icon: 'edit',
          },
        ],
        values: {},
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getTemplates(params) {
      this.request(this.$API.HTML_TEMPLATES.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;

        this.vTable.values.data = this.vTable.values.data.map((x) => {
          const aux = x;
          aux.onEdit = x.is_editable;
          return aux;
        });
      });
    },
    onEdit(item) {
      this.selectedTemplate = { ...item };
      this.editorOpen = true;
    },
    closeEditor() {
      this.selectedTemplate = null;
      this.editorOpen = false;
    },
    /* onDelete(item) {
      this.fireConfirm(
        'Deleting Template',
        `Are you sure you want to delete the Template "${item.title}"?`,
      )
        .then(() => {
          this.request(`${this.$API.HTML_TEMPLATES}${item.html_template_id}`, 'delete', {}, () => {
            this.fireSuccess('Student deactivated successfully.');
            // Reset Table after response
            this.$refs.vtable.init();
          });
        });
    }, */
  },
};
</script>

<style scoped>
</style>
