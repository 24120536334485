<!-- eslint-disable vue/no-v-html -->
<template>
  <vue-modal
    ref="modal"
    size="lg"
    @close="$emit('close')"
  >
    <template slot="title">
      Template Editor
    </template>
    <template slot="body">
      <div
        class="description"
        v-html="template.description"
      />
      <div
        class="description mt-4"
        v-html="subject_description"
      />
      <form-text
        v-model="form.subject"
        label="Email Subject"
        icon="description"
        class="mt-4"
      />
      <small style="margin-bottom: -10px; display:block; font-weight: 600">Template</small>
      <trumbowyg
        v-model="form.html"
        :config="config"
        class="form-control"
        name="resultHtml"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-success float-right"
        :disabled="isLoading"
        @click="saveTemplate"
      >
        <div v-if="!isLoading">
          Save Letter
        </div>
        <div v-else>
          Loading
        </div>
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import { FormText } from '@/components/Inputs';

export default {
  components: {
    FormText,
  },
  props: {
    template: {
      type: Object,
      default: () => ({
        html: null,
        html_template_id: null,
      }),
    },
  },
  data() {
    return {
      form: {
        html: null,
        subject: null,
      },
      isLoading: false,
      config: {
        btns: [
          ['viewHTML'],
          ['undo', 'redo'], // Only supported in Blink browsers
          ['formatting'],
          ['strong', 'em', 'del'],
          ['superscript', 'subscript'],
          ['link'],
          ['insertImage'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
        ],
        semantic: {
          div: 'div', // Editor does nothing on div tags now
        },
      },
      subject_description: null,
    };
  },
  mounted() {
    this.form.html = this.template.html;
    this.form.subject = this.template.subject?.subject;

    this.subject_description = '<b class="d-title">YOU CAN ACCESS TO THE FOLLOWING DATA IN THE SUBJECT</b> <br>'
        + '<ul>'
        + '  <li> <b>Student</b> <br>'
        + '    <ul>'
        + '      <li> <b>Number</b> = {{student.student_number}} </li>'
        + '      <li> <b>Full Name</b> = {{student.full_name}} </li>'
        + '      <li> <b>Email</b> = {{student.email}} </li>'
        + '      <li> <b>Phone</b> = {{student.phone}} </li>'
        + '      <li> <b>Nationality</b> = {{ student.country.nationality }} </li>'
        + '    </ul>'
        + '  </li>'
        + '</ul>';
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveTemplate() {
      this.isLoading = true;

      this.request(this.$API.HTML_TEMPLATES + this.template.html_template_id, 'put', this.form, () => {
        this.fireSuccess('Template updated successfully!');
        this.close();
        this.$emit('saved');
      });
    },
  },
};
</script>

<style scoped>
.description {
  background: #ccc;
  padding: 1.5em;
  border-radius: .5em;
  -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
  box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
  color: #161515;
}
</style>
